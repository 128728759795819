﻿import { UrlManager } from "../Utilities/UrlManager";
import { Utilities } from "../Utilities/Utilities";
import { ApiClient } from "../ApiClient";
import { SelectizeHelper } from "../Utilities/SelectizeHelper";


export class SearchList {
    readonly action:string = "GetSearchList";
    readonly containerCls:string = "search-page";
    readonly resultCls:string = "search-page-results";
    readonly loadingCls:string = "loading";
    readonly eventsAndOADataAction:string = "GetEventsAndOAData";

    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    messageManager: IMessageManager;
    selectizeHelper: SelectizeHelper;

    filterPrefixConfig: IFilterPrefixConfig;
    controllerUri: string;

    containerElement:HTMLElement = null;
	resultElement: HTMLElement = null;
	regionID: string = null;

    constructor(apiClient:IApiClient, utilities: Utilities, urlManager: IUrlManager, messageManager: IMessageManager,
        selectizeHelper: SelectizeHelper, controllerUri: string,
        filterPrefixConfig: IFilterPrefixConfig) {
        let me:this = this,
            element:JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.apiClient = apiClient;
        me.utilities = utilities;
        me.urlManager = urlManager;
        me.messageManager = messageManager;
		me.selectizeHelper = selectizeHelper;

        me.filterPrefixConfig = filterPrefixConfig;
        me.controllerUri = controllerUri;

        if (element.length === 1) {
            me.containerElement = element[0];
            me.initListeners();
        }

        if (jQuery("#search-term").val() === "") {
            jQuery("#search-term").focus();
        }
    }

    initListeners():void {
        let me:this = this;

        jQuery("#search-locations").change(():void => {
            me.runSearch();
        });
        jQuery("#search-categories").change(():void => {
            me.runSearch();
        });

        jQuery("#online-activity-toggle").click(():void => {
            me.runSearch();
        });

        jQuery("#btn-live-online").click(function():void {
            me.runSearch();
            /*
            if (jQuery(this).is(":checked")) {
                jQuery("#search-locations")[0].selectize.setValue("Online");
            }
            */
        });

        jQuery("#search-term").keyup((event:JQuery.Event):void => {
            if (event.which === 13) {
                me.runSearch();
            }
        });
    }

    initSlick(): void {
        let me: this = this,
            config: object = {
                prevArrow: false,
                nextArrow: false,
                infinite: false,
                swipeToSlide: true,
                accessibility: false,
                variableWidth: true
            };

        if (me.utilities.isTouchEnabled()) {
            jQuery('.search-scheduled-dates').each(function () {
                let dates: JQuery<HTMLElement> = jQuery(this).find(".search-item-date");
                if (dates.length > 0) {
                    dates.addClass("is-in-viewport");
                    jQuery(this).slick(config);
                }
            });
        }
    }


    runSearch():void {
        let me:this = this,
            locationFilterElement:JQuery<HTMLElement> = jQuery("#search-locations"),
            categoryFilterElement:JQuery<HTMLElement> = jQuery("#search-categories"),
            location:string = locationFilterElement.val() as string,
            locationUri: string = me.selectizeHelper.getUriDataAttributeForSelectedElement(locationFilterElement),
            categoryID:number = categoryFilterElement.val() as number,
            categoryUri: string = me.selectizeHelper.getUriDataAttributeForSelectedElement(categoryFilterElement),
            searchTerm:string = jQuery("#search-term").val() as string,
            liveOnline:boolean = jQuery("#live-online").is(":checked"),
            onlineActivity:boolean = jQuery("#online-activity").is(":checked"),
            ajaxUrl:string = me.constructAjaxUrl(searchTerm, location, categoryID, liveOnline, onlineActivity),
            url:string = me.constructUrl(searchTerm, locationUri, categoryID, categoryUri, liveOnline, onlineActivity),
            apiCallback:IApiCallback = {
                success: (data:any):void => {
                    jQuery(me.containerElement).find(`.${me.resultCls}`).html(data.html);
                    me.initSlick();
                    me.urlManager.pushState(null, document.title, url);
                },
                complete: (textStatus: string) => {
                    jQuery(me.containerElement).removeClass(me.loadingCls);
                },
                error:(textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(errorThrown);
                },
                scope: me
            };

        jQuery(me.containerElement).addClass(me.loadingCls);
        me.apiClient.getData(ajaxUrl, apiCallback);
    }

    constructAjaxUrl(searchTerm:string, location:string, categoryID:number, liveOnline:boolean, onlineActivity:boolean):string {
        let me:this = this,
			url: string = `${me.controllerUri}/${me.action}/`;

        if (searchTerm !== "") {
            url += "?searchTerm=" + me.urlManager.encodeUriComponent(searchTerm);

            if (location && location !== "") {
                url += "&location=" + me.urlManager.encodeUriComponent(location);
            }

            if (categoryID && categoryID > 0) {
                url += "&categoryID=" + categoryID;
            }

            if (liveOnline ) {
                url += "&liveOnline=true";
            }

            if (onlineActivity ) {
                url += "&onlineActivity=true";
            }
        } else {
            throw "No searchTerm";
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    constructUrl(searchTerm:string, location:string, categoryID:number,
                 categoryUri:string, liveOnline:boolean, onlineActivity:boolean):string {
        let me:this = this,
			url: string = `${me.controllerUri}`;

        if (searchTerm !== "") {
            url += `/${me.urlManager.encodeUriComponent(searchTerm)}`;

            // the order of this is important because the routes are set up in the same order
            if (location !== "") {
                url += `/${me.filterPrefixConfig.location}-${me.urlManager.encodeUriComponent(location)}`;
            }

            if (categoryID > 0 ) {
                url += `/${me.filterPrefixConfig.category}-${categoryID}-${me.urlManager.encodeUriComponent(categoryUri)}`;
            }

            if (liveOnline ) {
                url += `/${me.filterPrefixConfig.liveOnline}`;
            }

            if (onlineActivity ) {
                url += `/${me.filterPrefixConfig.onlineActivity}`;
            }
        } else {
            throw "No searchTerm";
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    GetEventsAndOADataForTemplate(templateID:number, apiCallback:IApiCallback):void {
        let me: this = this,
            url: string = me.urlManager.getAbsoluteUrl(`${me.controllerUri}/${me.eventsAndOADataAction}?templateID=${templateID}`);

        me.apiClient.getData(url, apiCallback);
    }
}

